import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Form, Button, Row, Col, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaEdit, FaCheck, FaTimes } from 'react-icons/fa';
import Avatar from 'react-avatar-edit';
import './UserSettings.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QRCode from 'qrcode.react'; // Import QRCode component

// Import service functions
import {
    fetchUserInformation,
    fetchUserOptions,
    fetchUserSettings,
    fetchUserDetails,
    checkNicknameAvailability,
    updateUserInformation,
    updateProfilePicture,
} from '../../services/apiService';

const UserSettings = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [userInfo, setUserInfo] = useState({
    email: '',
    email_verified: false,
    nickname: '',
    old_nickname: '',
    profilePicture: '',
    usage: 0,
    usageLimit: 100,
    profileVisibility: 'public',
    getNewsletter: false,
    showDisclaimer: true,
    publicId: '', // Add publicId to state
    accountType: 'free',
  });
  const [showAvatarEditor, setShowAvatarEditor] = useState(false);
  const [preview, setPreview] = useState(null);
  const [nicknameStatus, setNicknameStatus] = useState(null); // State to track nickname status

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        if (isAuthenticated) {
          const token = await getAccessTokenSilently();
          
          const userInfo = {};

          try {
            const dbInfo = await fetchUserInformation(token);
            if (dbInfo) {
              userInfo.usage = dbInfo.used_storage_space || 0;
            }
          } catch (error) {
            console.error('Failed to fetch user information:', error);
            toast.error('Failed to fetch user information.');
          }

          try {
            const userOptions = await fetchUserOptions(token);
            if (userOptions) {
              userInfo.profileVisibility = userOptions.profile_visibility || 'private';
              userInfo.getNewsletter = userOptions.receive_newsletter || false;
              userInfo.showDisclaimer = userOptions.show_disclaimer || true;
            }
          } catch (error) {
            console.error('Failed to fetch user options:', error);
            toast.error('Failed to fetch user options.');
          }

          try {
            const userSettings = await fetchUserSettings(token);
            if (userSettings) {
              userInfo.usageLimit = userSettings.usage_limit || 5;
              userInfo.accountType = userSettings.accountType || "free";
            }
          } catch (error) {
            console.error('Failed to fetch user settings:', error);
            toast.error('Failed to fetch user settings.');
          }

          try {
            const userDetails = await fetchUserDetails(token);
            if (userDetails) {
              userInfo.nickname = userDetails.nickname || '';
              userInfo.old_nickname = userDetails.nickname || '';
              userInfo.email = userDetails.email || '';
              userInfo.profilePicture = userDetails.profile_picture || '';
              userInfo.publicId = userDetails.public_id || ''; // Set publicId
            }
          } catch (error) {
            console.error('Failed to fetch user details:', error);
            toast.error('Failed to fetch user details.');
          }

          setUserInfo(prevState => ({
            ...prevState,
            ...userInfo
          }));
        }
      } catch (error) {
        console.error('Failed to fetch user information:', error);
        toast.error('Failed to fetch user information.');
      }
    };

    fetchUserInfo();
  }, [getAccessTokenSilently, isAuthenticated]);

  const checkNicknameAvailabilityHandler = async (nickname) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await checkNicknameAvailability(token, nickname);
      if (response && response.exists) {
        setNicknameStatus('unavailable');
      } else {
        setNicknameStatus('available');
      }
    } catch (error) {
      console.error('Error checking nickname availability:', error);
      setNicknameStatus('error');
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;

    if (name === 'nickname' && value !== userInfo.nickname) {
        if (value === userInfo.old_nickname) {
            setNicknameStatus(null); // Reset status if the nickname is unchanged
          } else {
            checkNicknameAvailabilityHandler(value);
          }
    }

    setUserInfo((prevState) => ({ ...prevState, [name]: fieldValue }));
  };

  const handleSaveChanges = async () => {
    if (nicknameStatus === 'unavailable') {
      toast.error('Nickname is already taken. Please choose another one.');
      return;
    }

    try {
      const token = await getAccessTokenSilently();
      await updateUserInformation(token, userInfo);
      toast.success('User information updated successfully.');
    } catch (error) {
      console.error('Failed to update user information:', error);
      toast.error('Failed to update user information.');
    }
  };

  const onClose = () => {
    setPreview(null);
  };

  const onCrop = (preview) => {
    setPreview(preview);
  };

  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > 11468800) {
      alert('File is too big!');
      elem.target.value = '';
    }
  };

  const handleProfilePictureSave = async () => {
    if (preview) {
      try {
        setShowAvatarEditor(false);
        const token = await getAccessTokenSilently();
        await updateProfilePicture(token, preview);
        toast.success('Profile picture updated successfully.');
        setUserInfo((prevState) => ({
          ...prevState,
          profilePicture: preview,
        }));
      } catch (error) {
        console.error('Failed to update profile picture:', error);
        toast.error('Failed to update profile picture.');
      }
    }
  };

  return (
    <Container className="user-settings-container">
      <ToastContainer />
      <h2 className="my-4">User Settings</h2>
      <Form>
        <Row className="mb-3">
          <Col md={4}>
            <h5>Profile</h5>
          </Col>
          <Col md={8}>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Edit Profile Picture</Tooltip>}
            >
              <div className="profile-picture-container" onClick={() => setShowAvatarEditor(true)}>
                <Image
                  src={userInfo.profilePicture || 'https://via.placeholder.com/150'}
                  roundedCircle
                  className="profile-picture"
                />
                <FaEdit className="edit-icon" />
              </div>
            </OverlayTrigger>
            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={userInfo.email}
                onChange={handleInputChange}
                disabled
              />
            </Form.Group>
            <Form.Group controlId="formNickname" className="mb-3">
              <Form.Label>Nickname</Form.Label>
              <Form.Control
                type="text"
                name="nickname"
                value={userInfo.nickname}
                onChange={handleInputChange}
              />
              {nicknameStatus === 'available' && userInfo.nickname !== '' && (
                <FaCheck style={{ color: 'green', marginLeft: '10px' }} />
              )}
              {nicknameStatus === 'unavailable' && userInfo.nickname !== '' && (
                <FaTimes style={{ color: 'red', marginLeft: '10px' }} />
              )}
            </Form.Group>
            <Form.Group controlId="formProfileVisibility" className="mb3">
              <Form.Label>Profile Visibility</Form.Label>
              <Form.Control
                as="select"
                name="profileVisibility"
                value={userInfo.profileVisibility}
                onChange={handleInputChange}
              >
                <option value="public">Public</option>
                <option value="private">Private</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={4}>
            <h5>Usage</h5>
          </Col>
          <Col md={8}>
            <Form.Group controlId="formUsage" className="mb-3">
              <Form.Label>Usage (GB)</Form.Label>
              <Form.Control
                type="number"
                name="usage"
                value={userInfo.usage}
                onChange={handleInputChange}
                disabled
              />
            </Form.Group>
            <Form.Group controlId="formUsageLimit" className="mb-3">
              <Form.Label>Usage Limit (GB)</Form.Label>
              <Form.Control
                type="number"
                name="usageLimit"
                value={userInfo.usageLimit}
                onChange={handleInputChange}
                disabled
              />
            </Form.Group>
            <Form.Group controlId="formAccountType" className="mb-3">
                <Form.Label>Account Type</Form.Label>
                <Form.Control
                  type="text"
                  name="accountType"
                  value={userInfo.accountType}
                  onChange={handleInputChange}
                  disabled
                />
              </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={4}>
            <h5>Options</h5>
          </Col>
          <Col md={8}>
            <Form.Group controlId="formGetNewsletter" className="mb-3">
              <Form.Check
                type="checkbox"
                name="getNewsletter"
                label="Receive Newsletter"
                checked={userInfo.getNewsletter}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formShowDisclaimer" className="mb-3">
              <Form.Check
                type="checkbox"
                name="showDisclaimer"
                label="Show Disclaimer"
                checked={userInfo.showDisclaimer}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={4}>
            <h5>Public ID</h5>
          </Col>
          <Col md={8}>
            {userInfo.publicId && (
              <Form.Group controlId="formPublicId" className="mb-3">
                <QRCode value={`https://cheerclip.com/friends/${userInfo.publicId}`} />
              </Form.Group>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 8, offset: 4 }}>
            <Button variant="primary" onClick={handleSaveChanges}>
              Save Changes
            </Button>
          </Col>
        </Row>
      </Form>

      {showAvatarEditor && (
        <div className="avatar-editor">
          <Avatar
            width={390}
            height={295}
            exportSize={300}
            onCrop={onCrop}
            onClose={onClose}
            onBeforeFileLoad={onBeforeFileLoad}
            src={preview}
          />
          {preview && (
            <div>
              <Image src={preview} roundedCircle className="profile-picture-preview" />
              <Button variant="primary" onClick={handleProfilePictureSave}>
                Save Profile Picture
              </Button>
            </div>
          )}
        </div>
      )}
    </Container>
  );
};

export default UserSettings;
