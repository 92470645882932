import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './VideoList.css';
import VideoListItem from '../VideoListItem/VideoListItem';
import { FaThLarge, FaTh, FaThList } from 'react-icons/fa';
import { toast } from 'react-toastify';


function VideoList({ title, videos, uploadProgress, onDelete, onEdit }) {
  const [gridSize, setGridSize] = useState('medium');
  const [filterOption, setFilterOption] = useState('all');
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [playingVideoId, setPlayingVideoId] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    // Apply filtering and sorting every time 'videos', 'filterOption', or 'searchQuery' changes
    filterAndSortVideos();
  }, [videos, filterOption, searchQuery]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleGridSizeChange = (size) => {
    setGridSize(size);
  };

  const handleFilterChange = (option) => {
    setFilterOption(option);
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterAndSortVideos = () => {
    let filtered;
    const now = new Date();
    switch (filterOption) {
      case 'today':
        const startOfDay = new Date(now.setHours(0, 0, 0, 0));
        filtered = videos.filter(video => new Date(video.metadata.upload_date) >= startOfDay);
        break;
      case 'last3Days':
        filtered = videos.filter(video => (now - new Date(video.metadata.upload_date)) / (1000 * 60 * 60 * 24) <= 3);
        break;
      case 'last7Days':
        filtered = videos.filter(video => (now - new Date(video.metadata.upload_date)) / (1000 * 60 * 60 * 24) <= 7);
        break;
      case 'lastMonth':
        filtered = videos.filter(video => (now - new Date(video.metadata.upload_date)) / (1000 * 60 * 60 * 24) <= 30);
        break;
      default:
        filtered = videos;
    }
    
    // Filter videos based on search query
    if (searchQuery) {
      filtered = filtered.filter(video => video.hashtags.includes(searchQuery));
    }

    // Sort videos by upload date in descending order
    filtered.sort((a, b) => new Date(b.metadata.upload_date) - new Date(a.metadata.upload_date));
    setFilteredVideos(filtered);
  };

  const handleVideoPlay = (videoId) => {
    setPlayingVideoId(videoId);
  };

  return (
    <div className="container mt-4">
      <h2>{title}</h2>
      <div className="controls">
        <div className="grid-size">
          {!isMobile && (
            <FaThLarge
              onClick={() => handleGridSizeChange('large')}
              className={`grid-icon ${gridSize === 'large' ? 'selected' : ''}`}
            />
          )}
          <FaTh
            onClick={() => handleGridSizeChange('medium')}
            className={`grid-icon ${gridSize === 'medium' ? 'selected' : ''}`}
          />
          <FaThList
            onClick={() => handleGridSizeChange('small')}
            className={`grid-icon ${gridSize === 'small' ? 'selected' : ''}`}
          />
        </div>
        <div className="filter-options">
          <select value={filterOption} onChange={(e) => handleFilterChange(e.target.value)}>
            <option value="all">All</option>
            <option value="today">Today</option>
            <option value="last3Days">Last 3 days</option>
            <option value="last7Days">Last 7 days</option>
            <option value="lastMonth">Last month</option>
          </select>
        </div>
        <div className="search-input">
          <input
            type="text"
            placeholder="Search by hashtag"
            value={searchQuery}
            onChange={handleSearchQueryChange}
          />
        </div>
      </div>
      <div className={`video-grid ${gridSize}`}>
        {filteredVideos.length > 0 ? (
          filteredVideos.map(video => (
            <VideoListItem 
              key={video.id} 
              video={video}
              isPlaying={playingVideoId === video.id}
              onVideoPlay={handleVideoPlay}
              uploadProgress={uploadProgress}
              onDelete={onDelete}
              onEdit={onEdit}
            />
          ))
        ) : (
          <div>No videos found</div>
        )}
      </div>
    </div>
  );
}

export default VideoList;
