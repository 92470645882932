import React, { useEffect, useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap'; // Use this to integrate with React Router
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import the service functions
import { fetchUserInformation, fetchUserDetails } from '../../services/apiService';

import './NavigationBar.css';

function NavigationBar() {
  const { loginWithRedirect, logout, isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [accountInfo, setAccountInfo] = useState({
    username: '',
    storageUsage: 0, // in GB
    numberOfVideos: 0
  });

  // Fetch user information
  const fetchUserInfo = useCallback(async () => {
    try {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently();

        // Fetch user information
        const dbInfo = await fetchUserInformation(token);
        
        // Fetch user details
        const userDetails = await fetchUserDetails(token);
  
        if (dbInfo && userDetails) {
          setAccountInfo({
            username: userDetails.nickname || '', // or user.name or any suitable attribute from Auth0 user profile
            storageUsage: dbInfo.used_storage_space || 0,
            numberOfVideos: dbInfo.number_of_videos || 0,
          });
        } else {
          console.error('User info not found');
        }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      toast.error('Failed to fetch user information. Please try again later.');
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserInfo();
    }
  }, [isAuthenticated, user, fetchUserInfo]);

  return (
    <>
      <Navbar bg="light" expand="lg" className="shadow-sm">
        <Navbar.Brand as="div" className="fw-bold fs-4">
          Video Processing App
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/* Other navigation items */}
          </Nav>
          <Nav className="ms-auto">
            <NavDropdown 
              title="Account" 
              id="basic-nav-dropdown" 
              align="end"
              onToggle={(isOpen) => {
                if (isOpen && isAuthenticated) {
                  fetchUserInfo(); // Fetch user info only when the dropdown is opened
                }
              }}
            >
              {isAuthenticated ? (
                <>
                  <NavDropdown.ItemText>Username: {accountInfo.username}</NavDropdown.ItemText>
                  <NavDropdown.ItemText>Storage Usage: {accountInfo.storageUsage.toFixed(2)} GB</NavDropdown.ItemText>
                  <NavDropdown.ItemText>Number of Videos: {accountInfo.numberOfVideos}</NavDropdown.ItemText>
                  <NavDropdown.Divider />
                  <LinkContainer to="/settings">
                    <NavDropdown.Item>Settings</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/">
                    <NavDropdown.Item>Main Page</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                    Logout
                  </NavDropdown.Item>
                </>
              ) : (
                <NavDropdown.Item onClick={() => loginWithRedirect({ authorizationParams: { redirect_uri: window.location.origin } })}>
                  Login
                </NavDropdown.Item>
              )}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default NavigationBar;
