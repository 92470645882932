// utilityService.js
import moment from 'moment';

export const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };
  
  export const formatTimeToHHMMSS = (seconds) => {
    const format = (val) => `0${Math.floor(val)}`.slice(-2);
    const hours = format(seconds / 3600);
    const minutes = format((seconds % 3600) / 60);
    const secs = format(seconds % 60);
    return `${hours}:${minutes}:${secs}`;
  };

  export const formatDate = (dateString) => {
    return moment(dateString).format('MMMM Do YYYY');
  };

  
  export const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};
