import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import DisclaimerSection from '../../components/DisclaimerSection/DisclaimerSection';
import UploadSection from '../../components/UploadSection/UploadSection';
import VideoList from '../../components/VideoList/VideoList';
import Footer from '../../components/Footer/Footer';
import { useAuth0 } from '@auth0/auth0-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VideoEditor from '../VideoEditor/VideoEditor';
import FriendsPage from '../FriendsPage/FriendsPage';
import UserSettings from '../UserSettings/UserSettings';

// Import the API service functions
import { fetchUserVideos, uploadVideo, deleteVideo, fetchSessionId } from '../../services/apiService';

function App() {
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const { isAuthenticated, user, getAccessTokenSilently, isLoading } = useAuth0();
  const [session_id, setSession_id] = useState('');
  const [videoToEdit, setVideoToEdit] = useState(null);
  const [isEditorOpen, setIsEditorOpen] = useState(false);

  const openEditor = (video) => {
    setVideoToEdit(video);
    setIsEditorOpen(true);
  };

  const closeEditor = () => {
    setIsEditorOpen(false);
    setVideoToEdit(null);
  };

  // Fetch session ID
  useEffect(() => {
    const fetchSessionIdAndSet = async () => {
      try {
        const sessionId = await fetchSessionId(isAuthenticated, getAccessTokenSilently);
        setSession_id(sessionId);
        console.log('Session ID:', sessionId);
      } catch (error) {
        console.error('Error fetching session ID:', error);
      }
    };

    if (!isLoading) {
      fetchSessionIdAndSet();
    }
  }, [isAuthenticated, getAccessTokenSilently, isLoading]);

  // Fetch Videos (using the service now)
  const fetchVideos = useCallback(async () => {
    try {
      const videos = await fetchUserVideos(isAuthenticated, user, session_id, getAccessTokenSilently);
      const videosWithHashtags = videos.map(video => ({
        ...video,
        hashtags: video.hashtags || ''
      }));
      setUploadedVideos(videosWithHashtags);
    } catch (error) {
      console.error('Error fetching videos:', error);
      toast.error('Failed to fetch videos. Please try again later.');
    }
  }, [isAuthenticated, user, getAccessTokenSilently, session_id]);

  useEffect(() => {
    if (!isLoading && (isAuthenticated || session_id)) {
      fetchVideos();
    }
  }, [fetchVideos, isLoading, session_id, isAuthenticated]);

  // Handle video delete
  const handleDelete = async (id) => {
      await deleteVideo(id);
      fetchVideos();
  };

  // Upload section: Add uploading video and show progress
  const addUploadingVideo = async (file, id, metadata) => {
    setUploadedVideos(prevVideos => [
      ...prevVideos,
      { id, original_name: file.name, status: 'Uploading...', progress: 0 , metadata: metadata}
    ]);
  };

  // Update progress of video upload
  const updateUploadProgress = (progress, fileId) => {
    setUploadedVideos(prevVideos =>
      prevVideos.map(video =>
        video.id === fileId
          ? { ...video, progress, status: progress === 100 ? 'Uploaded' : 'Uploading...' }
          : video
      )
    );
  };

  return (
    <div>
      <ToastContainer />
      <Router>
        <NavigationBar />
        <Routes>
          <Route path="/friends" element={<FriendsPage user={user} />} />
          <Route path="/settings" element={<UserSettings user={user} />} />
          <Route path="/" element={
            isEditorOpen ? (
              <VideoEditor
                onRequestClose={closeEditor}
                video={videoToEdit}
                session_id={session_id}
              />
            ) : (
              <>
                <DisclaimerSection />
                <UploadSection
                  onUploadStart={addUploadingVideo}
                  updateUploadProgress={updateUploadProgress}  // Pass updateUploadProgress
                  onUploadSuccess={fetchVideos}               // Refresh after upload
                  session_id={session_id}
                />
                <VideoList
                  title="Uploaded Videos"
                  videos={uploadedVideos}
                  onUploadSuccess={fetchVideos}
                  enableUpload={true}
                  onDelete={handleDelete}
                  onEdit={openEditor}
                />
                <Footer />
              </>
            )
          } />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
