import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from '../../services/axios';
import { useAuth0 } from '@auth0/auth0-react';


function UploadSection({ onUploadStart, updateUploadProgress, onUploadSuccess, onUploadError, session_id }) {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();

  const extractMetadata = async (file) => {
  
    const videoElement = document.createElement('video');
    const objectUrl = URL.createObjectURL(file);
  
    return new Promise((resolve) => {
      videoElement.src = objectUrl;
      videoElement.onloadedmetadata = () => {
        const metadata = {
          name: file.name,
          size: file.size,
          type: file.type,
          duration: videoElement.duration,
          width: videoElement.videoWidth,
          height: videoElement.videoHeight,
        };
        resolve(metadata);
      };
    });
  };
  

  const onDrop = useCallback(async (acceptedFiles) => {
    const uploadFiles = acceptedFiles.map(async (file) => {
      try {
        // Extract metadata and thumbnail
        const fileMetadata = await extractMetadata(file);
        console.log('Extracted Metadata:', fileMetadata);

        // Prepare request parameters
        const params = {
          fileName: file.name,
          fileType: file.type,
          sessionId: session_id,
          fileMetadata: fileMetadata,
        };

        // Include Authorization header if authenticated
        let presignedResponse;
        if (isAuthenticated && user) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          presignedResponse = await axios.get('/videos/upload-S3-video', { headers, params});
        } else {
          presignedResponse = await axios.get('/videos/upload-S3-video', { params });
        }

        const presignedUrl = presignedResponse.data.url;

        axios.interceptors.response.use(null, async (error) => {
          const config = error.config;
          if (!config || !config.retry || config.retryCount >= config.retryLimit) {
            return Promise.reject(error);
          }
          config.retryCount = config.retryCount || 0;
          config.retryCount += 1;
          await new Promise((resolve) => setTimeout(resolve, config.retryDelay || 1000)); // Wait 1 second before retrying
          return axios(config);
        });

        onUploadStart(file, presignedResponse.data.id, fileMetadata); // Add video to the list with initial progress and metadata

        const options = {
          method: 'PUT',
          headers: {
            'Content-Type': file.type,
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            updateUploadProgress(progress, presignedResponse.data.id);
          },
          retry: true,
          retryLimit: 3,
          retryDelay: 2000,
        };

        await axios.put(presignedUrl, file, options);

        onUploadSuccess(); // Notify parent component that the upload was successful
      } catch (error) {
        console.error('Error uploading file:', error);
        if (onUploadError) {
          onUploadError(error);
        }
      }
    });

    await Promise.all(uploadFiles);
  }, [isAuthenticated, getAccessTokenSilently, user, onUploadStart, onUploadSuccess, onUploadError, session_id]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: true });

  return (
    <div className="container mt-4">
      <h2>Upload a Video</h2>
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        {isDragActive ? <p>Drop the files here ...</p> : <p>Drag 'n' drop some files here, or click to select files</p>}
      </div>
    </div>
  );
}

export default UploadSection;
