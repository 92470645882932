// VideoTag.js
export default class SubClip {
    constructor(startTime, endTime, state="new", id = Date.now()) {
        this.id = id; // Unique identifier for each subclip
        this.startTime = startTime;
        this.endTime = endTime;
        this.state = state; // new, auto-predicted, or user-defined
    }

    // Move the entire clip
    moveClip(newStartTime) {
        const duration = this.endTime - this.startTime;
        this.startTime = newStartTime;
        this.endTime = newStartTime + duration;
    }

    // Move the start tag of the clip
    moveStartTag(newStartTime) {
        this.startTime = newStartTime;
    }

    // Move the end tag of the clip
    moveEndTag(newEndTime) {
        this.endTime = newEndTime;
    }
}