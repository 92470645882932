// DisclaimerSection.js
import React from 'react';

function DisclaimerSection() {
  return (
    <div className="container mt-4">
      <div className="alert alert-warning" role="alert">
        <strong>Disclaimer:</strong> This website is a personal project and is provided as-is without any guarantees or warranty. The use of this website is at your own risk. The owner is not responsible for any inaccuracies, errors, or damages arising from its use.
      </div>
    </div>
  );
}

export default DisclaimerSection;
