import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import CustomTimeBar from '../../components/Timebar/CustomTimeBar';
import SubClip from '../../components/Subclip/SubClip';

import './VideoEditor.css';

function VideoEditor({ onRequestClose, video, session_id }) {
    const [playing, setPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const playerRef = useRef(null);
    const videoUrl = video ? video.url : null;
    const [subClips, setSubClips] = useState([]);

    useEffect(() => {
        if (video && video.timestamps) {
            const initialSubClips = video.timestamps.map(ts => 
                new SubClip(parseFloat(ts.start_time), parseFloat(ts.end_time), "auto-predicted")
            );
            setSubClips(initialSubClips);
        }
    }, [video]);

    const setVideoTime = (newTime) => {
        playerRef.current.seekTo(newTime);
    };

    const handlePlayPause = () => {
        setPlaying(!playing);
    };

    const handleProgress = progress => {
        setCurrentTime(progress.playedSeconds);
    };

    const handleDuration = duration => {
        setDuration(duration);
    };

    return (
        <div className="video-player-container">
            <div className="player-wrapper">
                <ReactPlayer
                    ref={playerRef}
                    url={videoUrl}
                    playing={playing}
                    onProgress={handleProgress}
                    onDuration={handleDuration}
                    width="100%"
                    height="100%"
                    controls={false}
                />
            </div>
            {duration > 0 && (
                <div className="timebar-wrapper">
                    <CustomTimeBar
                        currentTime={currentTime}
                        duration={duration}
                        onPlayPause={handlePlayPause}
                        playing={playing}
                        setVideoTime={setVideoTime}
                        SubClips={subClips}
                        video={video}
                        onRequestClose={onRequestClose}
                        session_id={session_id}
                    />
                </div>
            )}
        </div>
    );
}

export default VideoEditor;
