import React, { useState, useEffect } from 'react';
import axios from '../../services/axios';
import { useAuth0 } from '@auth0/auth0-react';
import { ToastContainer, toast } from 'react-toastify';
import { Container, Row, Col, Form, Button, Card, Accordion } from 'react-bootstrap';
import Select from 'react-select';
import { FaTrash, FaUserPlus, FaUserShield } from 'react-icons/fa';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './FriendsPage.css';

const FriendsPage = ({ user }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [friends, setFriends] = useState([]);
  const [friendGroups, setFriendGroups] = useState([]);
  const [newFriendEmail, setNewFriendEmail] = useState('');
  const [newGroupName, setNewGroupName] = useState('');
  const [filter, setFilter] = useState({ value: 'both', label: 'Show Both' });
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredFriends, setFilteredFriends] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [groupEmails, setGroupEmails] = useState({});

  const filterOptions = [
    { value: 'both', label: 'Show Both' },
    { value: 'friends', label: 'Show Friends' },
    { value: 'groups', label: 'Show Groups' }
  ];

  useEffect(() => {
    filterAndSortFriendsGroups();
  }, [friends, friendGroups, filter, searchQuery]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchFriends = async () => {
    try {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.get('/friends/friends', { headers });
      setFriends(response.data.friends);
    } catch (error) {
      console.error('Failed to fetch friends:', error);
      toast.error('Failed to fetch friends.');
    }
  };

  const fetchFriendGroups = async () => {
    try {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.get('/friends/friend-groups', { headers });
      setFriendGroups(response.data.groups);
    } catch (error) {
      console.error('Failed to fetch friend groups:', error);
      toast.error('Failed to fetch friend groups.');
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchFriends();
      fetchFriendGroups();
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  const handleAddFriend = async () => {
    try {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      await axios.post('/friends/add-friend', { email: newFriendEmail }, { headers });
      setNewFriendEmail('');
      toast.success('Friend added successfully.');
      fetchFriends();
    } catch (error) {
      console.error('Failed to add friend:', error);
      toast.error('Failed to add friend.');
    }
  };

  const handleCreateGroup = async () => {
    try {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      await axios.post('/friends/create-friend-group', { name: newGroupName }, { headers });
      setNewGroupName('');
      toast.success('Group created successfully.');
      fetchFriendGroups();
    } catch (error) {
      console.error('Failed to create group:', error);
      toast.error('Failed to create group.');
    }
  };

  const handleAddFriendToGroup = async (groupId) => {
    const newMember = friends.find(friend => friend.email === groupEmails[groupId]);
    if (newMember) {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        await axios.post(`/friends/friend-groups/${groupId}/add-friend`, { email: newMember.email }, { headers });
        setGroupEmails(prevEmails => ({ ...prevEmails, [groupId]: '' }));
        toast.success('Friend added to group successfully.');
        fetchFriendGroups();
      } catch (error) {
        console.error('Failed to add friend to group:', error);
        toast.error('Failed to add friend to group.');
      }
    } else {
      toast.error('Friend not found.');
    }
  };

  const handleRemoveFriendFromGroup = async (groupId, memberId) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      await axios.delete(`/friends/friend-groups/${groupId}/remove-friend/${memberId}`, { headers });
      toast.success('Friend removed from group successfully.');
      fetchFriendGroups();
    } catch (error) {
      console.error('Failed to remove friend from group:', error);
      toast.error('Failed to remove friend from group.');
    }
  };

  const handleMakeAdmin = async (groupId, memberId) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      await axios.post(`/friends/friend-groups/${groupId}/make-admin/${memberId}`, {}, { headers });
      toast.success('Friend promoted to admin successfully.');
      fetchFriendGroups();
    } catch (error) {
      console.error('Failed to promote friend to admin:', error);
      toast.error('Failed to promote friend to admin.');
    }
  };

  const filterAndSortFriendsGroups = () => {
    let filteredFriends = friends;
    let filteredGroups = friendGroups;

    if (searchQuery) {
      filteredFriends = friends.filter(friend =>
        friend.email.toLowerCase().includes(searchQuery.toLowerCase())
      );

      filteredGroups = friendGroups.filter(group =>
        group.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        group.members.some(member =>
          member.email.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }

    setFilteredFriends(filteredFriends);
    setFilteredGroups(filteredGroups);
  };

  return (
    <Container>
      <ToastContainer />
      <h2 className="my-4">Friends Page</h2>
      <div className="controls">
        <Select
          value={filter}
          onChange={(selected) => setFilter(selected)}
          options={filterOptions}
        />
        <div className="search-input">
          <input
            type="text"
            placeholder="Search by name or email"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      <Row>
        {(filter.value === 'both' || filter.value === 'friends') && filteredFriends.map(friend => (
          <Col md={6} key={friend.id}>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>{friend.name}</Card.Title>
                <Card.Text>{friend.email}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
        {(filter.value === 'both' || filter.value === 'groups') && filteredGroups.map(group => (
          <Col md={6} key={group.id}>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>{group.name}</Card.Title>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Members</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        {group.members.map(member => (
                          <li key={member.id} className="d-flex align-items-center justify-content-between">
                            {member.email}
                            {group.admins && group.admins.some(admin => admin.email === user.email) && (
                              <div>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  className="ms-2"
                                  onClick={() => handleRemoveFriendFromGroup(group.id, member.id)}
                                >
                                  <FaTrash />
                                </Button>
                                {!group.admins.some(admin => admin.email === member.email) && (
                                  <Button
                                    variant="secondary"
                                    size="sm"
                                    className="ms-2"
                                    onClick={() => handleMakeAdmin(group.id, member.id)}
                                  >
                                    <FaUserShield />
                                  </Button>
                                )}
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                      {group.admins && group.admins.some(admin => admin.email === user.email) && (
                        <>
                          <Form.Control
                            type="email"
                            value={groupEmails[group.id] || ''}
                            onChange={(e) => setGroupEmails({ ...groupEmails, [group.id]: e.target.value })}
                            placeholder="Enter friend's email"
                            className="mb-2"
                          />
                          <Button onClick={() => handleAddFriendToGroup(group.id)} variant="primary" block>
                            <FaUserPlus className="me-2" /> Add Friend to Group
                          </Button>
                        </>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Row className="mt-4">
        <Col md={6} className="mb-3">
          <h3>Add Friend</h3>
          <Form.Control
            type="email"
            value={newFriendEmail}
            onChange={(e) => setNewFriendEmail(e.target.value)}
            placeholder="Enter friend's email"
            className="mb-2"
          />
          <Button onClick={handleAddFriend} variant="primary" block>
            <FaUserPlus className="me-2" /> Add Friend
          </Button>
        </Col>
        <Col md={6} className="mb-3">
          <h3>Create Friend Group</h3>
          <Form.Control
            type="text"
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.target.value)}
            placeholder="Enter group name"
            className="mb-2"
          />
          <Button onClick={handleCreateGroup} variant="primary" block>
            <FaUserPlus className="me-2" /> Create Group
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default FriendsPage;
