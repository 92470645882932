import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './pages/HomePage/App';

const domain = "dev-cfqpcijqv66jgdb5.us.auth0.com"; //process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = "pNNHr1lOqODNPKVXNI4oYfRQlkKjXgh1"; //process.env.REACT_APP_AUTH0_CLIENT_ID;

// Create a root.
const root = createRoot(document.getElementById('root'));

// Render the app in the root.
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{ 
        redirect_uri: window.location.origin,
        audience: "https://api.cheerclip.com" }}
      cacheLocation={'localstorage'}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);


